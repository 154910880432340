//全体共通で使うCSS定義
body{
  word-wrap: break-word;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.5;
}
h1,h2,h3,.serif{
  font-family: 'Noto Serif JP', serif;
  color: #000;
}

a{
  color: #000;
  &:hover{
    color: #000;
    opacity: 0.5;
    text-decoration: none;
  }

  //スマホリンク設定
  &[href^="tel:"] {
    cursor: default;
    &:hover{
      opacity: 1;
    }
  }
}
p{
  color: #000;
  letter-spacing: .02em;
  line-height: 1.8;
}
h1{}
h2{}
h3{}
h4{}
h5{}

.section_container{
  margin: 82px auto;
  width: 1140px;
  max-width: 100%;
  @include mq-max(sm){
    margin: 35px auto;
  }
}
.small_width{
  width: 900px;
  max-width: 100%;
}

.mainimg{
  @include mq-max(md){
    padding: 65px 0 !important;
  }
  #about &{
    background-image: url(/inc/image/about/mainimg.jpg);
  }
  #service &{
    background-image: url(/inc/image/service/mainimg.jpg);
  }
  #office &{
    background-image: url(/inc/image/office/mainimg.jpg);
  }
}

.br_sp{
  display: none;
  @include mq-max(xs){
    display: block;
  }
}

.br_pc{
  @include mq-max(xs){
    display: none;
  }
}

.tel{
  pointer-events: none;
  @include mq-max(xs){
    pointer-events: auto;
  }
}
