header{
  height: 105px !important;
  @include mq-max(sm){
    height: 60px !important;
  }

  &.header-effect-shrink{
    height: 105px;
    @include mq-max(sm){
      height: 60px;
    }
  }
  &:not(.h-100){
    &.header-body{
      height: 100px !important;
      min-height: 100px !important;
    }
  }

  .header-container{
    height: 105px !important;
    min-height: 105px !important;
    @include mq-max(sm){
      height: 60px !important;
      min-height: 60px !important;
    }
    .header-logo{
      margin: 25px 0 27px !important;
      @include mq-max(sm){
        width: auto !important;
        height: 35px !important;
        margin: 0 !important;
        img{
          width: auto;
          height: 35px !important;
        }
      }
      h1{
        margin: 0;
        line-height: inherit;
        font-size: 0px;
      }
    }
    .header-btn-collapse-nav{
      .fas{
        color: #fff;
      }
    }
  }
}
