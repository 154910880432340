.container{
  .row{
    margin: 0;
    div[class*=col]{
      padding: 0;
    }
  }
}

//写真とテキストの2カラムのボックス
.imgbox{
  div[class*=col]{
    &:first-child{
      padding-right: 30px;
      @include mq-max(mmd){
        padding-right: 0;
      }
    }
  }
  .onlysp{
    display: none;
    @include mq-max(mmd){
      display: block;
    }
  }
  .onlypc{
    display: block;
    @include mq-max(mmd){
      display: none;
    }
  }

    img{
      width: 100%;
      border-radius: 10px;
      max-width: 100%;
    }

  p{
    font-size: 16px;
  }

  .btnwrap{
    @include mq-max(sm){
      text-align: center;
    }
    .btn{
      margin: 30px 0;
      @include mq-max(mmd){
        width: 100%;
        height: 55px;
        margin: 0;
      }
    }
  }
}

//スタッフ紹介のボックス
.staff_wrap{
  justify-content: space-between;
  .staff_box{
  margin: 0;
  &.col-lg-4{
    max-width: 31.5%;
    @include mq-max(sm){
      max-width: 49%;
    }
    @include mq-max(xs){
      max-width: 100%;
    }
  }
  .card{
    margin-bottom: 18px;
    border-width: 3px;
    .card-body{
      padding: 1.25rem 1.5rem;
      h3{
        font-size: 20px;
        margin-bottom: 0;
        @include mq-max(xs){
          font-size: 18px;
        }
      }
      .position{
        color: #23AC38;
        font-family: 'Noto Serif JP', serif;
        margin-bottom: 0;
      }
      .card-text{
        font-size: 16px;
        @include mq-max(xs){
          font-size: 14px;
        }
      }
    }
  }
}
}
//「justify-content:space-between」の最後の行を左寄せにするための対処方法（https://webtan.tech/space-between-lastline/）
.staff_wrap{
  &::after{
    display: block;
    content:"";
    width: 31.5%;
  }
}


//backgraund-imageが入ったボックス
.service_list{
  .row{
    justify-content: space-between;
    flex-wrap: wrap;
    .service_item{
      width: 200px;
      @include mq-max(sm){
        width: 49% !important;
      }
      .card-body{
        padding: 0.8rem;
        @include mq-max(xs){
          padding: .4em;
        }
        @include mq-max(xs){
          margin: 11px;
        }
        h3{
          font-size: 18px;
          height: 2.5em;
          line-height: 1.2;
          letter-spacing: inherit;
          white-space: nowrap;
          @include mq-max(xs){
            font-size: 14px;
          }
          &.text_xxs{
            @include mq-max(xxs){
              font-size: 13.5px;
            }
          }
        }
        .btn{
          max-width: 90%;
          height: auto;
          padding: 11px 19px;
          width: 200px;
          @include mq-max(sm){
            padding: 10px 5px;
          }
        }
      }
    }
  }
}

//お問い合わせボックス
.contact_box{
  border: 3px solid #E8E8E8;
  border-radius: 6px;
  padding: 32px 0 50px;
  text-align: center;
  @include mq-max(xs){
    padding: 16px 0 25px;
  }
  a{
    font-size: 26px;
    color: #23AC38;
    letter-spacing: .04em;
    line-height: 1;
    @include mq-max(xs){
      font-size: 18px;
    }
    &::before{
      font-family: "Font Awesome 5 Free";
      content: '\f095';
      font-weight: 900;
      margin-right: 0.3em;
      @include mq-max(xs){
        font-size: 18px;
      }
    }
  }
  .time{
    letter-spacing: .04em;
    font-size: 16px;
    @include mq-max(xs){
      font-size: 14px;
    }
  }
  p{
    color: #000;
    position: relative;
    &::before{
      content: "";
      width: 375px;
      height: 2px;
      border-bottom: 2px solid #E8E8E8;
      position: absolute;
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);
      @include mq-max(xs){
        width: 90%;
      }
    }
  }
  .tel{
    a{
      &::before{
        transform: scale(-1, 1);
        display: inline-block;
        font-size: 21px;
      }
    }
  }
  .mail{
    padding-top: 10px;
    a{
      &::before{
        content: '\f0e0';

      }
    }
  }
}

// かぎかっこで囲む（理念）
.box_bracket {
  font-family: 'Noto Serif JP', serif;
  span{
    font-size: 24px;
    position: relative;
    padding:0.25em 1em;
    @include mq-max(xs){
      display: block;
      font-size: 18px;
    }
    &:before,&:after{
      content:'';
      width: 37px;
      height: 34px;
      position: absolute;
      display: inline-block;
      @include mq-max(xs){
      width: 24px;
      height: 22px;
      }
    }
    &:before{
      border-left: solid 1px #23AC38;
      border-top: solid 1px #23AC38;
      top: -.5em;
      left: -.5em;
      @include mq-max(xs){
        top: 0;
        left: 0;
      }
    }
    &:after{
      border-right: solid 1px #23AC38;
      border-bottom: solid 1px #23AC38;
      bottom: -.5em;
      right: -.5em;
      @include mq-max(xs){
        bottom: 0;
        right: 0;
      }
    }
  }
  p{
    margin: 0;
    padding: 0;
  }
}
