h2{
  &.dot{
    position: relative;
    margin-bottom: 58px;
    letter-spacing: .04em;
    font-weight: 500;
    @include mq-max(sm){
      font-size: 1.7em;
      margin-bottom: 35px;
    }
    &::before{
      position: absolute;
      content: url(/inc/image/common/img_title_dot.png);
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

h3{
  font-size: 24px;
  margin-bottom: 40px;
  letter-spacing: .02em;
  font-weight: 500;
  @include mq-max(sm){
    font-size: 1.4em;
    margin-bottom: 20px;
  }
}

.ttl_lower{
  font-size: 46px !important;
  letter-spacing: 0;
  @include mq-max(md){
    font-size: 30px !important;
  }
}
