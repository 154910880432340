#pageTop{

}
.scroll-to-top{
  background-color: #fff !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.16);
  border-radius: 3px;
  .fas{
    color: #23AC38 !important;;
  }
}
