#header{
  .header-container{
    .header-nav{
    @include mq-max(sm){
      padding: 0;
      min-height: 60px;
    }
    }

    .header-nav-main{
      nav{
        & > ul{
          & > li{
            & > a{
              font-size: 14px;
              padding: 0 .7rem !important;
              letter-spacing: .06em;

              &.active{
                color: #23AC38;
                height: inherit;
                min-height: auto;
                @include mq-max(sm){
                  color: #000 !important;
                }
              }
            }
          }
          &:not(:hover){
            & > li{
              & > a{
                &.active{
                  color: #23AC38;
                }
              }
            }
          }
        }
      }
      .tel{
        text-align: center;
        line-height: 1;
        padding: 33px 0 0 39px;
        position: relative;
        @include mq-max(md){
          padding: 33px 0;
        }
        .onlysp{
          display: none;
          @include mq-max(sm){
            display: block;
            margin-bottom: 0;
            font-size: 13px;
            letter-spacing: 0.1em;
          }
        }
        &::before{
          content: "";
          position: absolute;
          width: 1px;
          height: 19px;
          border-left: 1px solid #C3C3C3;
          top: 42px;
          left: 10px;
          @include mq-max(md){
            content: none;
          }
        }
        a{
          color: #23AC38;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: .06em;
          &::before{
            font-family: "Font Awesome 5 Free";
            content: '\f095';
            font-weight: 900;
            margin-right: 0.2em;
            transform: scale(-1, 1);
            display: inline-block;
            font-size: 14px;
          }
          &:hover{
            text-decoration: none;
            opacity: 0.5;
          }
        }
        p{
          font-size: 12px;
          letter-spacing: .24em;
        }
      }
    }
  }

  .header-nav.header-nav-links nav > ul:not(:hover) > li{
    & > a{
      &.active{
        color: #23AC38 !important;
      }
    }
  }
  .header-nav.header-nav-links:not(.header-nav-light-text) nav > ul > li > a, #header .header-nav.header-nav-line:not(.header-nav-light-text) nav > ul > li > a{
    color: #000 !important;
    @include mq-max(md){
      margin: .8em;
    }
    &:hover{
      color: #23AC38 !important;
    }
  }
  .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a{
    color: #777;
    &.active{
      @include mq-max(mmd){
        background-color: #fff !important;
      }
    }
  }
  .header-btn-collapse-nav{
    background-color: #24ac39;
    @include mq-max(md){
      height: 48px !important;
    }
    @include mq-max(sm){

      height: 35px !important;
    }
    .fas{
      @include mq-max(md){
        font-size: 22px;
      }
      @include mq-max(md){
        font-size: 14px;
      }

    }
  }
}

/*
#header .header-nav.header-nav-links:not(.header-nav-light-text) nav > ul > li > a, #header .header-nav.header-nav-line:not(.header-nav-light-text) nav > ul > li > a{
  &:hover{
    color: #23AC38;
  }
}
#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a.active{
  background-color: #fff;
}
#header .header-nav-main:not(.header-nav-main-mobile-dark) nav > ul > li > a{
  color: #777;
}

#header .header-nav.header-nav-links:not(.header-nav-light-text) nav > ul > li > a, #header .header-nav.header-nav-line:not(.header-nav-light-text) nav > ul > li > a{
  color: #000;
  @include mq-max(md){
    margin: .8em;
  }
}
*/
