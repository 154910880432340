#footer{
  background-color: #F5F5F5;
  border-top: none;
  .container{
    &.footer_container{
      padding: 33px 0;
      @include mq-max(sm){
        padding: 0 15px;
      }
      .footer_logo{
        display: inherit;

        @include mq-max(xs){
          display: block;
        }
        .link{
          img{
            height: 76px;
            @include mq-max(lg){
              height: 65px;
            }
            @include mq-max(md){
              height: 50px;
            }
            @include mq-max(sm){
              height: 76px;
            }
          }
        }
      }
    }
    .address_wrap{
      justify-content: flex-end;
      flex-wrap: nowrap;
      @include mq-max(xs){
        display: block;
      }

      .address{
      line-height: 1.5;
      color: #000;
      letter-spacing: .04em;
      font-size: 14px;
      margin-left: 1rem;
      @include mq-max(sm){
        margin-left: 0;
      }
      }
      a:not(.btn){
        color: #000;
      }
    }
  }

  .footer-copyright{
    &.footer-copyright-style-2{
      background-color: #F5F5F5;
      border-top: 1px solid #E2E0E0;
    }
    .container{
      padding: 30px;
      @include mq-max(md){
        padding: 15px;
      }
      p{
      color: #000 !important;
      font-size: 16px;
      letter-spacing: inherit;
      @include mq-max(md){
        font-size: 12px;
      }
    }
    }

  }
}
.link{
  cursor: pointer;
}
