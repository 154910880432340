.table{
  border-bottom: 1px solid #DEE2E6;
  th,td{
    @include mq-max(xs){
      display: block;
    }
  }
  th{
    width: 180px;
    padding: 1rem 1rem 1rem 2rem;
    font-weight: 700;
    @include mq-max(xs){
      padding: 1em 0;
      width: 100%;
    }
  }
  td{
    padding: 1rem;
    @include mq-max(xs){
      padding: 1em 0;
    }
    ul{
      padding: 0;
      margin-bottom: 0;
      li{
        list-style-type: none;
      }
    }
    dl{
      margin: 0;
      padding: 0;
      dt{
        img{
          width: auto;
          margin-left: 127px;
          @include mq-max(xs){
            margin-left: 30px;
          }
        }
      }
      dd{
        margin-bottom: 0;
        img{
          height: 100px;
        }
      }
      &.group{
        padding-bottom: 50px;
        dt{
          border-bottom: 1px solid #DEE2E6;
          padding: 1.5rem .8rem;
        }
        .row{
          @include mq-max(sm){
            justify-content: space-around;
          }
          dd{
            padding: 1.5rem .8rem;
            padding-bottom: 0;

            img{
              height: 70px;
            }
          }
        }
      }
    }
    a{
      color: #000;
    }
  }
}
